import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CropResolutionService } from '../services/cropresolution.service';
import { AppState } from '../services/appstate.service';
import { CropResolutionActions } from '../actions/cropresolution.actions';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class CropResolutionEffects {
  constructor(
    private cropResolutionService: CropResolutionService,
    private actions$: Actions,
    private store: Store<AppState>,
    private cropResolutionActions: CropResolutionActions,
  ) { }

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CropResolutionActions.FETCH_CROPRESOLUTIONS),
            switchMap(
                action => this.cropResolutionService.fetchAll().pipe(
                    map(
                    searchResult => this.cropResolutionActions.fetchCropResolutionsSuccess(searchResult)
                    ),
                    catchError((err) => of(this.cropResolutionActions.fetchCropResolutionsFailed(err)))
                )
            )
        )
    );
};
