import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { TagService } from '@services/tag.service';

import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'app-tag-add',
    templateUrl: './tag-add.component.html',
    styleUrls: ['./tag-add.component.scss']
})
@Sentry.TraceClassDecorator()
export class TagAddComponent {
    status: any;
    tag: any;
    colors: any;
    tagRegex = /[^A-Za-z0-9üéâäàåçêëèïîìôöòûùáíóúñ\-\s\/]+/gi;
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private tagService: TagService
    ) {
        this.status = { ok: false, id: 'test' };
        this.tag = { className: 'tag_white', type: 'tag' };
        this.colors = [
            { color: 'tag_white', label: 'White' },
            { color: 'tag_blue', label: 'Blue' },
            { color: 'tag_red', label: 'Red' },
            { color: 'tag_orange', label: 'Orange' },
            { color: 'tag_green', label: 'Green' },
            { color: 'tag_yellow', label: 'Yellow' },
            { color: 'tag_cyan', label: 'Cyan' }
        ];
    }

    addTag(tag: any, close = false) {
        if (tag._id != null && tag._id.length > 2) {
            if (tag._id.match(this.tagRegex) != null) {
                this.status.validateError = 'You can only create a tag with letters, numbers, slash (/), minus (-) and spaces'
            } else {
                this.loading = true;
                this.tagService.add(this.tag).subscribe((x) => {
                    this.status = x;
                    this.loading = false;
                    if (close === true) {
                        setTimeout(() => {
                            this.dialogRef.close('yes');
                        }, 1000);
                    }
                }, (err) => {
                    this.status = err.error;
                    this.loading = false;
                });
            }
        }
    }

}
