import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';

import { ImageTagSearchComponent } from '@components/image-tag-search/image-tag-search.component';
import { TagSearchComponent } from '@components/tag-search/tag-search.component';

import { LazyImgDirective } from '@directives/lazy-img.directive';

@NgModule({
  declarations: [
    LazyImgDirective,
    ImageTagSearchComponent,
    TagSearchComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatChipsModule
  ],
  exports: [
    LazyImgDirective,
    ImageTagSearchComponent,
    TagSearchComponent,
    MatDialogModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatChipsModule
  ],
  providers: []
})
export class SharedModule { }
