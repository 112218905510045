import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RightsGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot) {
        const profile = JSON.parse(localStorage.getItem('profile'));

        if (profile != null) {
            if (profile['https://mediaspirit.io/app_metadata'] != null) {
                if (profile['https://mediaspirit.io/app_metadata'].rights) {
                    if (route != null && route.routeConfig != null) {
                        if (route.routeConfig.path === 'image-upload') {
                            if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('imageUpload') > -1) {
                                return true;
                            }
                        } else if (route.routeConfig.path === 'tag-manager') {
                            if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagAdd') > -1 ||
                                profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagDelete') > -1) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        this.router.navigate(['./image-search']);
        return false;
    }
}

