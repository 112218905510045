import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-image-view-page',
  templateUrl: './image-view-page.component.html',
  styleUrls: ['./image-view-page.component.scss']
})
@Sentry.TraceClassDecorator()
export class ImageViewPageComponent implements OnInit {
  imageUrl = '';

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    const prefix = String(this.route.snapshot.paramMap.get('prefix') || '').replace(/%2F/g, '/');
    const imageData = String(this.route.snapshot.paramMap.get('imageData') || '').replace(/%2F/g, '/');
    if (imageData === 'undefined') {
      this.imageUrl = prefix + `?token=${localStorage.getItem('id_token')}`;
    } else {
      this.imageUrl = prefix + '/' + imageData;
    }
  }
}
