import { Injectable } from '@angular/core';

import { SelectedImageModel } from '@models/selectedimage';
import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class SelectedImageActions {
    static UPDATE_SELECTED_IMAGE = 'UPDATE_SELECTED_IMAGE';

    updateSelectedImage(selectedImage: SelectedImageModel): ActionWithPayload {
        return {
            type: SelectedImageActions.UPDATE_SELECTED_IMAGE,
            payload: selectedImage
        };
    }
}
