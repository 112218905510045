import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth0Service } from '@sitespirit/ngx-auth0';

import { AppState } from '@services/appstate.service';
import { AuthActions } from '@actions/auth.actions';

@Injectable()
export class AuthService {
    constructor(private store: Store<AppState>, private authActions: AuthActions, private auth0Service: Auth0Service,
        private jwtHelper: JwtHelperService, private router: Router) {
        if (this.authenticated()) {
            let profile = JSON.parse(localStorage.getItem('profile'));
            this.store.dispatch(this.authActions.IsloggedIn(profile));
        } else {
            this.store.dispatch(this.authActions.IsloggedOut());
        }
    }

    public authenticated() {
        let rawToken = localStorage.getItem('id_token');
        if (rawToken != null && rawToken != 'null') {
            return !this.jwtHelper.isTokenExpired(rawToken);
        }
        return false;
    }

    public login(err: any = null) {
        let that = this;
        return new Promise((resolve, reject) => {
            let observable = that.auth0Service.loginByDialog(err);
            observable.subscribe(async next => {
                const decodedJWT = await this.jwtHelper.decodeToken(next.idToken);
                localStorage.setItem('profile', JSON.stringify(decodedJWT));
                localStorage.setItem('id_token', next.idToken);
                that.store.dispatch(that.authActions.loginSuccess(next.profile));
                if (localStorage.getItem('oldUrl') && String(localStorage.getItem('oldUrl')).length > 0) {
                    this.router.navigateByUrl(String(localStorage.getItem('oldUrl')));
                } else {
                    location.reload();
                }
                resolve(next.idTokenPayload);
            }, error => {
                reject(error);
            });
        });
    }

    public logout() {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            localStorage.removeItem('profile');
            localStorage.removeItem('id_token');
            that.store.dispatch(this.authActions.logoutSuccess());
            resolve();
        });
    }
}

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private jwtHelper: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const oldUrl = localStorage.getItem('oldUrl') || '';
        if (oldUrl.length > 0) {
            localStorage.removeItem('oldUrl');
        }
        if (state.url.indexOf('image-redirect') > -1) {
            localStorage.setItem('oldUrl', state.url);
        }
        let rawToken = String(localStorage.getItem('id_token'));
        if (rawToken != null && rawToken != 'null') {
            return !this.jwtHelper.isTokenExpired(rawToken);
        }
        return false;
    }
}
