import { Injectable } from '@angular/core';

@Injectable()
export class CsvService {
  csvImageHeaders = ['imageId', 'name', 'fileSize', 'height', 'width', 'nlCleanUrl', 'tags']
  csvTagHeaders = ['_id', 'className', 'count']

  downloadFile(data: any, filename: any) {
    this.addTagHeaders(data);
    let csvData = this.ConvertToCSV(data, this.csvImageHeaders);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadTags(data: any, filename: any) {
    return new Promise(resolve => {
      let csvData = this.ConvertToCSV(data, this.csvTagHeaders);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement('a');
      let url = URL.createObjectURL(blob);
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename + '.csv');
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      resolve(true);
    });
  }

  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'SEP=;';
    let headers = '';

    for (let index in headerList) {
      if (headerList[index] !== 'tags') {
        if (headerList[index]) {
          headers += headerList[index] + ';';
        } else {
          headers += ';';
        }
      }
    }
    headers = headers.slice(0, -1);
    str += row + '\r\n';
    str += headers + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        if (headerList[index] != null) {
          let head = headerList[index];
          if (head === 'tags') {
            for (let j = 0; j < array[i][head].length; j++) {
              if (array[i][head][j]['_id'] != null) {
                line += ';' + array[i][head][j]['_id'];
              } else {
                line += ';';
              }
              if (array[i][head][j]['className'] != null) {
                line += ';' + array[i][head][j]['className'];
              } else {
                line += ';';
              }
              if (array[i][head][j]['type'] != null) {
                line += ';' + array[i][head][j]['type'];
              } else {
                line += ';';
              }
            }
          } else {
            if (array[i][head] != null) {
              if (line !== '') {
                line += ';' + array[i][head];
              } else {
                line += array[i][head];
              }
            } else {
              line += ';';
            }
          }
        }
      }
      str += line + '\r\n';
    }
    return str.replace('_id', 'name');
  }

  addTagHeaders(data: any) {
    for (const image of data) {
      for (let i = 0; i < image.tags.length; i++) {
        if (this.csvImageHeaders.indexOf('tag' + (i + 1) + 'name') < 1) {
          this.csvImageHeaders.push('tag' + (i + 1) + 'name');
          this.csvImageHeaders.push('tag' + (i + 1) + 'className');
          this.csvImageHeaders.push('tag' + (i + 1) + 'type');
        }
      }
    }
  }
}
