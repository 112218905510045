import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, map, switchMap, catchError } from 'rxjs/operators';

import { ImageService } from '@services/image.service';
import { ImageActions } from '@actions/image.actions';
import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class ImageEffects {
  search$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.SEARCH_IMAGE),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.search(action.payload).pipe(
        map((searchResult => this.imageActions.searchImageSuccess(searchResult, action.payload.sortType))),
        catchError((err) => of(this.imageActions.searchImageFailed(err)))
      ))
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.UPDATE_IMAGE),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.updateImage(action.payload)),
      map(result => this.imageActions.updateImageSuccess(result))
    );
  });

  update_properties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.UPDATE_IMAGE_PROPERTIES),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.updateImageProperties(action.payload)),
      map((result: any) => this.imageActions.updateImageSuccess(result))
    );
  });

  update_edit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.UPDATE_IMAGE_EDIT),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.updateImageEdit(action.payload)),
      map(result => this.imageActions.updateImageEditSuccess(result))
    );
  });

  addTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.ADD_IMAGE_TAG),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.addTag(action.payload.image, action.payload.tag)),
      map((result: any) => this.imageActions.updateImageSuccess(result))
    );
  });

  changePriority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.CHANGE_IMAGE_PRIORITY),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.changeImagePriority(action.payload.image, action.payload.tag, action.payload.priority)),
      map((result: any) => this.imageActions.changeImagePrioritySuccess(result))
    );
  });

  addRecognizedImageTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.ADD_RECOGNIZED_IMAGE_TAG),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.addRecognizedImageTag(action.payload.image, action.payload.tag)),
      map(result => this.imageActions.addImageTag(result.image, result.tag))
    );
  });

  deleteTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.DELETE_IMAGE_TAG),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.deleteTag(action.payload.image, action.payload.tag)),
      map((result: any) => this.imageActions.updateImageSuccess(result))
    );
  });

  deleteCognitiveTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.DELETE_COGNITIVE_TAG),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.deleteCognitiveTag(action.payload.image, action.payload.tag)),
      map((result: any) => this.imageActions.updateImageSuccess(result))
    );
  });

  deleteImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.DELETE_IMAGE),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => this.imageService.deleteImage(action.payload)),
      map((result: any) => this.imageActions.updateImageSuccess(result))
    );
  });

  cognitiveRecognizeImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.COGNITIVE_RECOGNIZE_IMAGE),
      filter((action: ActionWithPayload) => action.payload !== ''),
      switchMap((action: ActionWithPayload) => {
        return this.imageService.cognitiveRecognizeImage(action.payload)
      }),
      map((result: any) => this.imageActions.cognitiveRecognizeImageSuccess(result))
    );
  });

  searchNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImageActions.SEARCH_IMAGE_NEXTPAGE),
      switchMap((action: ActionWithPayload) => this.imageService.searchNextPage().pipe(
        map(searchResult => this.imageActions.searchImageNextPageSuccess(searchResult)),
        catchError((err) => of(this.imageActions.searchImageNextPageFailed(err)))
      ))
    );
  });

  constructor(
    private imageService: ImageService,
    private actions$: Actions,
    private imageActions: ImageActions,
  ) { }
};
