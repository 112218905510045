import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenGetter } from '../app.module';

@Injectable()
export class CropResolutionService {
  cloudantProxyUrl = 'https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/';
  openwhiskUrl = 'https://sd9cksc8i8.execute-api.eu-central-1.amazonaws.com/prod/'
  header = {
    headers: new HttpHeaders().set('Authorization', `${tokenGetter()}`)
  }

  constructor(private authHttp: HttpClient) { }

  fetchAll(): Observable<any> {
    return this.authHttp.get(this.cloudantProxyUrl + 'db/allcropres');
  }

  retrieve(id: string): Observable<any> {
    return this.authHttp.get(this.cloudantProxyUrl + 'db/' + id);
  }

  upload(obj: Object): Observable<any> {
    let fullObj: Object = obj;
    return this.authHttp.post(this.openwhiskUrl + 'cropres', fullObj, this.header);
  }
}
