
import { map, switchMap } from 'rxjs/operators';
import { Store, compose } from '@ngrx/store';

import { AppState } from '@services/appstate.service';
import {
  getImageIds,
  getStatus as getImageStatus,
  getQuery as getImageQuery,
  getBookmark as getImageBookmark,
  getSort as getImageSort,
  getTotalRows as getImageTotalRows,
  getCurrentLoadedRowNum as getImageCurrentLoadedRowNum,
  getError as getImageSearchErrorObj
} from './imagesearch';
import {
  getImageEntities as fromImagegetImageEntities,
  getImage as fromImageGetImage,
  hasImage as fromImageHasImage,
  getImages as fromImageGetImages
} from './image';
import {
  getTagIds,
  getStatus as getTagStatus,
  getQuery as getTagQuery
} from './tagsearch';
import { hasTag as fromTagHasTag, getTags as fromTagGetTags } from './tag';

export function getImageState() {
  return (state$: Store<AppState>) => state$
    .select(s => s.image);
}

export function getImageEntities() {
  return compose(fromImagegetImageEntities(), getImageState());
}

export function getImage(id: string) {
  return compose(fromImageGetImage(id), getImageState());
}

export function hasImage(id: string) {
  return compose(fromImageHasImage(id), getImageState());
}

export function getImages(imageIds: string[]) {
  return compose(fromImageGetImages(imageIds), getImageState());
}


export function getImageSearchState() {
  return (state$: Store<AppState>) => state$
    .select(s => s.imagesearch);
}
export function getImageSearchImageIds() {
  return compose(getImageIds(), getImageSearchState());
}

export function getImageSearchStatus() {
  return compose(getImageStatus(), getImageSearchState());
}

export function getImageSearchQuery() {
  return compose(getImageQuery(), getImageSearchState());
}

export function getImageSearchBookmark() {
  return compose(getImageBookmark(), getImageSearchState());
}

export function getImageSearchSort() {
  return compose(getImageSort(), getImageSearchState());
}

export function getImageSearchTotalRows() {
  return compose(getImageTotalRows(), getImageSearchState());
}

export function getImageSearchsCurrentLoadedRowNum() {
  return compose(getImageCurrentLoadedRowNum(), getImageSearchState());
}

export function getImageSearchResults(): any {
  return (state$: Store<AppState>) => state$
    .pipe(getImageSearchImageIds(), switchMap((imageIds: any) => state$.pipe(getImages(imageIds))));
}

export function getImageSearchError() {
  return compose(getImageSearchErrorObj(), getImageSearchState());
}

export function getCropResolutionEntities(): any {
  return (state$: Store<AppState>) => state$
    .select(s => s.cropresolution.entities);
};

export function getCropResolution(id: string) {
  return (state$: Store<AppState>) => state$
    .select(s => s.cropresolution.entities[id]);
}

export function getCropResolutions(CropResolutionIds: string[]) {
  return (state$: Store<AppState>) => state$
    .pipe(getCropResolutionEntities())
    .pipe(map((entities: any) => CropResolutionIds.map(id => entities[id])));
}

export function hasCropResolution(id: string) {
  return (state$: Store<AppState>) => state$
    .select(s => (s.cropresolution.ids.indexOf(id) > 0));
}

// TAGS

export function getTags(tagIds: string[]) {
  return compose(fromTagGetTags(tagIds), getTagState());
}

export function getTagState() {
  return (state$: Store<AppState>) => state$
    .select(s => s.tag);
}

export function getTagSearchState() {
  return (state$: Store<AppState>) => state$
    .select(s => s.tagsearch);
}

export function getSelectedImage(): any {
  return (state$: Store<AppState>) => state$
    .select(s => s.selectedimage);
}

export function getTagSearchTagIds() {
  return compose(getTagIds(), getTagSearchState());
}

export function getTagSearchStatus() {
  return compose(getTagStatus(), getTagSearchState());
}

export function getTagSearchQuery() {
  return compose(getTagQuery(), getTagSearchState());
}

export function getTagSearchResults(): any {
  return (state$: Store<AppState>) => state$
    .pipe(getTagSearchTagIds(), switchMap((tagIds: any) => state$.pipe(getTags(tagIds))));
}

export function hasTag(id: string) {
  return compose(fromTagHasTag(id), getTagState());
}
