import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NotificationService } from '@services/notification.service';
import { ImageService } from '@services/image.service';
import { AppState } from '@services/appstate.service';
import { ImageActions } from '@actions/image.actions';
import { AblyActions } from '@actions/ably.actions';
import { getCropResolutionEntities } from '../reducers';

import * as Ably from 'ably';

@Injectable()
export class AblyService {
    client: any;
    channel: any;
    cropResolutions$!: Observable<any>;
    url!: string;
    path!: string;
    busy = false;
    deletedImages = 0;
    processedDeletedImages = 0;

    constructor(private notificationService: NotificationService,
        private imageService: ImageService, private store: Store<AppState>, private imageActions: ImageActions,
        private ablyActions: AblyActions) { }

    start() {
        let metaDataSelector = 'https://mediaspirit.io/app_metadata';
        let profile = JSON.parse(localStorage.getItem('profile'));
        if (profile != null && profile[metaDataSelector] != null) {
            let that = this;
            let ablySettings = profile[metaDataSelector];

            this.client = new Ably.Realtime({
                key: 'tHxJkQ.uNQyLA:g6IT8JHqc8CHJ4XK'
            });
            this.channel = this.client.channels.get(ablySettings.env);
            // this.pubnubService.init({
            //     subscribeKey: 'sub-c-2d39a3d4-4386-11e7-b66e-0619f8945a4f',
            //     authKey: ablySettings.ablyToken,
            //     uuid: profile.env // used to be auth0 id(profile.sub), but is now the client id(profile.env)(for example executive);
            // });

            this.cropResolutions$ = this.store.pipe(getCropResolutionEntities());

            this.cropResolutions$.subscribe(x => {
                for (let property in x) {
                    if (x[property].hasOwnProperty('url') === true && x[property].hasOwnProperty('path') === true
                        && x[property].path.indexOf('square') === 0) {
                        this.url = x[property].url.replace('http://', 'https://s3-eu-west-1.amazonaws.com/');
                        this.path = x[property].path;
                        if (x[property].path.indexOf('square/xxs') === 0 || x[property].path.indexOf('square/md') === 0) {
                            break;
                        }
                    }
                }
            });

            // this.pubnubService.subscribe({ channels: [ablySettings.env] });
            this.channel.subscribe((obj: any) => {
                if (obj.data != null) {
                    if (obj.data.status === 'tagBulk') {
                        this.store.dispatch(this.ablyActions.busy());
                        this.busy = true;
                        let message = `Bulk ${obj.data.data.params.action} tag started`;
                        that.notificationService.html(`<div class="sn-title">${obj.data.data.params.tag}</div>
                        <div class="sn-content">${message}</div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'tag-merge' && obj.data.action === 'done') {
                        this.store.dispatch({
                            type: 'MANAGER_TAGS_MERGE', payload: {
                                sourceTag: obj.data.data.tag,
                                destinationTag: obj.data.data.replacementTag
                            }
                        })
                    }
                    if (obj.data.status === 'tag-delete') {
                        let message = 'Delete tag done';
                        if (obj.data.data.status) {
                            if (obj.data.action === 'done') {
                                this.store.dispatch(this.ablyActions.refresh());
                            }
                            const done = obj.data.data.status.initialTotal - obj.data.data.status.leftoverTotal;
                            const total = obj.data.data.status.initialTotal;
                            message = `Deleted ${done} of ${total}`;
                        } else {
                            if (obj.data.action === 'done') {
                                this.store.dispatch(this.ablyActions.refresh());
                            }
                            that.notificationService.html(`<div class="sn-title">${obj.data.data.tag}</div>
                             <div class="sn-content">${message}</div>`, 'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'tag-update') {
                        if (obj.data.action === 'done') {
                            this.store.dispatch(this.ablyActions.refresh());
                        }
                        let message = 'Update tag done';
                        that.notificationService.html(`<div class="sn-title">${obj.data.data.tag}</div>
                         <div class="sn-content">${message}</div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageTagAdd') {
                        that.imageService.retrieveImage(obj.data.data.imageId).subscribe(image => {
                            that.store.dispatch(that.imageActions.updateImageSuccess(image));
                        });
                        that.notificationService.html(`<div class="sn-title">Tag added to image</div> <div
                         class="sn-content"><a href="/image/${obj.data.data.imageId}">${obj.data.data.tagId}</a></div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageTagBulk' && obj.data.data.imageIds != null) {
                        for (let imageId of obj.data.data.imageIds) {
                            that.imageService.retrieveImage(imageId).subscribe(image => {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            });
                            that.notificationService.html(`<div class="sn-title">Tag added to image</div> <div
                             class="sn-content"><a href="/image/${imageId}">${obj.data.data.tagIds[0]}</a></div>`, 'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'imageTagDelete') {
                        that.imageService.retrieveImage(obj.data.data.imageId).subscribe(image => {
                            if (image != null) {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            }
                        });
                        that.notificationService.html(`<div class="sn-title">Tag deleted from image</div> <div
                         class="sn-content"><a href="/image/${obj.data.data.imageId}">${obj.data.data.tagId}</a></div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageUploadProcessed') {
                        that.notificationService.html(`<div class="sn-title">Upload processed</div>
                         <div class="sn-content"><a href="/image/${obj.data.data.id}">${obj.data.data.id}</a></div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageMoveCrops') {
                        that.notificationService.html(`<div class="sn-title">Image info processed</div> <div
                         class="sn-content"><a href="/image/${obj.data.data.imageId}">${obj.data.data.imageId}</a></div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageUpdated' || obj.data.status === 'moveCrops') {
                        const imageId = obj.data.data.id || obj.data.data.imageId || null;
                        if (imageId != null) {
                            that.imageService.retrieveImage(imageId).subscribe(image => {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            });
                            that.notificationService.html(`<div class="sn-title">Updated image</div> <div
                             class="sn-content"><a href="/image/${imageId}">${imageId}</a></div>`, 'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'imageCognitiveRecognize') {
                        if (obj.data.data.imageId != null) {
                            that.imageService.retrieveImage(obj.data.data.imageId).subscribe(image => {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            });
                            that.notificationService.html(`<div class="sn-title">Started image recognition</div>
                             <div class="sn-content"><a href="/image/${obj.data.data.imageId}">
                             ${obj.data.data.imageId}</a></div>`, 'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'individualImageRecognitionWatsonDone') {
                        if (obj.data.data.imageDocId != null) {
                            that.imageService.retrieveImage(obj.data.data.imageDocId).subscribe(image => {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            });
                            that.notificationService.html(`<div class="sn-title">Cognitive recognition success</div>
                             <div class="sn-content"><a href="/image/${obj.data.data.imageDocId}">
                             ${obj.data.data.imageDocId}</a></div>`, 'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'imageUpload') {
                        if (obj.data.data.hasOwnProperty('key') === true) {
                            that.notificationService.html(`<div class="sn-title">Image uploaded successfully</div>
                             <div class="sn-content">${obj.data.data.key}</div>`, 'success');
                        } else {
                            // that.notificationService.html(`<div class="sn-title">Image uploaded successfully</div>
                            // <div class="sn-content">${obj.data.data.activationId}</div>`,'success');
                        }
                        return;
                    }
                    if (obj.data.status === 'imageUnDelete') {
                        if (obj.data.data.imageId != null) {
                            that.imageService.retrieveImage(obj.data.data.imageId).subscribe(image => {
                                that.store.dispatch(that.imageActions.updateImageSuccess(image));
                            });
                            that.notificationService.html(`<div class="sn-title">Started image UnDelete</div>
                             <div class="sn-content"><a href="/image/${obj.data.data.imageId}">
                             ${obj.data.data.imageId}</a></div>`, 'success', {
                                timeOut: 10000,
                                showProgressBar: true,
                                pauseOnHover: true,
                            });
                        }
                        return;
                    }
                    if (obj.data.status === 'imageMoveToPrivate') {
                        that.notificationService.html(`<div class="sn-title">Image moved successfully</div>
                         <div class="sn-content">${obj.data.data.activationId}</div>`, 'success');
                        return;
                    }
                    if (obj.data.status === 'imageCrop') {
                        let htmlString = `<div class="sn-title sn-title-img"><span>
                        <img src="${this.url}/${this.path}${obj.data.data.cleanUrl}"></span><span> Crop processed</span></div>`;
                        that.imageService.retrieveImage(obj.data.data.docId).subscribe(image => {
                            that.store.dispatch(that.imageActions.updateImageSuccess(image));
                        });
                        that.notificationService.html(htmlString, 'success');
                        return;
                    }
                    if (obj.data.status === 'imagePurge') {
                        this.processedDeletedImages++;
                        that.notificationService.html(`<div class="sn-title">Image deleted</div>
                        <div class="sn-title">successfully</div>`, 'success');

                        if (this.deletedImages > 0 && this.processedDeletedImages === this.deletedImages) {
                            this.deletedAllImagesProcessed();
                            this.deletedImages = 0;
                            this.processedDeletedImages = 0;
                        }
                        return;
                    }
                    // that.notificationService.info(obj.data.status, JSON.stringify(obj.data));
                }
            });
        }
    }

    deletedAllImagesProcessed() {
        this.notificationService.html(`<div class="sn-title">Deleted all images</div>`, 'success', {
            timeOut: 20000,
            showProgressBar: true,
            pauseOnHover: true,
        });
    }
}
