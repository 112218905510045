import { ErrorHandler, Injectable } from '@angular/core';
//import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let window: any;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  headers: HttpHeaders;
  reportedErrors: string[] = [];
  url: string = 'https://7e4d3014-3c0a-440b-a325-893ced8c00db-bluemix.cloudant.com/mediaspirit-reports';
  username: string = 'andsomenterfaredtherrieu';
  password: string = '2c27cfad46d792b1e08298833c5628c520761834';
  constructor(/*private notificationsService: NotificationsService, */private http: HttpClient) {
    let credentials = `${this.username}:${this.password}`;
    this.headers = new HttpHeaders({
      'Authorization': `Basic ${btoa(credentials)}`
    });
  }

  sendReport(error: any) {
    const stringifiedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
    if (this.reportedErrors.indexOf(stringifiedError) === -1) {
      this.reportedErrors.push(stringifiedError);
      let errorObject: any = {
        date: new Date(),
        url: window.location.href
      }
      errorObject = Object.assign(errorObject, JSON.parse(stringifiedError));
      return this.http.post(`${this.url}/_design/search/_search/errors`, {
        q: `lineNumber:${errorObject.lineNumber} AND columnNumber:${errorObject.columnNumber} AND message:${JSON.stringify(errorObject.message)} AND fileName:"${errorObject.fileName}"`
      }, {
        headers: this.headers
      }).toPromise().then((result: any) => {
        if (result != null && typeof result === 'object' && result.total_rows === 0) {
          return this.http.post(this.url, errorObject, {
            headers: this.headers
          }).toPromise().then(x => {
            console.log('REPORTED_ERROR:', errorObject);
          }).catch(e => {
            console.log(e);
          });
        }
        return null;
      }).catch(e => {
        console.log(e);
        return e;
      })
    }
    return null;
  }

  handleError(error: any) {
    const whitelist: any = [];
    if (whitelist.indexOf(error.message) > -1) {
      //console.log('Whitelisted error:',error);
    } else {
      console.log(error);
      /*this.sendReport(error);
      this.notificationsService.error('Error detected',error.message, {
        timeOut: 8000
      });*/
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    // WARNING Note David: enabling this causes effects to crash when a error occurs
    //console.log('JS Error:',error);
    throw error;
  }

}
