<h1 mat-dialog-title>Add New Crop Resolution</h1>
<mat-dialog-content>
  <div class="crop-list">
    <table *ngIf="data" class="striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Path</th>
          <th>Width</th>
          <th>Height</th>
          <th>Ratio</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.path}}
          </td>
          <td>
            {{item.width}}
          </td>
          <td>
            {{item.height}}
          </td>
          <td>
            {{item.ratio}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="input-fields">
    <mat-form-field class="third-width">
      <mat-label>Description</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="description">
    </mat-form-field>
    <mat-form-field class="third-width">
      <mat-label>Path</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="path">
    </mat-form-field>
    <mat-form-field class="third-width">
      <mat-label>Width</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="width" (ngModelChange)="recalc('width')"
        (keydown.arrowup)="increase('width')" (keydown.arrowdown)="decrease('width')">
    </mat-form-field>
    <mat-form-field class="quarter-width">
      <mat-label>Height</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="height" (ngModelChange)="recalc('height')"
        (keydown.arrowup)="increase('height')" (keydown.arrowdown)="decrease('height')">
    </mat-form-field>
    <mat-form-field class="quarter-width">
      <mat-label>Ratio</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="ratio" (ngModelChange)="recalc('ratio')"
      (keydown.arrowup)="increase('ratio')" (keydown.arrowdown)="decrease('ratio')">
    </mat-form-field>
    <mat-form-field class="quarter-width">
      <mat-label>Quality</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="quality"
      (keydown.arrowup)="increase('quality')" (keydown.arrowdown)="decrease('quality')">
    </mat-form-field>
    <mat-form-field class="quarter-width">
      <mat-label>Output Extention</mat-label>
      <mat-select [(value)]="selectedExtention">
        <mat-option value="keep">Keep extention</mat-option>
        <mat-option value="jpg">JPG</mat-option>
        <mat-option value="png">PNG</mat-option>
        <mat-option value="webp">WEBP</mat-option>
      </mat-select>
      <!-- <p>You selected: {{selectedExtention}}</p> -->
    </mat-form-field>
    <div class="preview-container">
      <div *ngIf="width == null || height == null || width < 1 || height < 1" class="img-container">
        <img class="preview-img" src="assets/img/placeholder.png" alt="eerste">
      </div>
      <div *ngIf="width != null && height != null && width > 0 && height > 0" class="img-container"
        [ngStyle]="{ 'width': previewWidth + 'px', 'height': previewHeight + 'px' }">
        <img class="preview-img" src="assets/img/placeholder.png" alt="tweede">
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="right" mat-raised-button color="primary" [disabled]="checkDisabled()" [mat-dialog-close]="getData()">Add Crop</button>
  <button class="right" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>