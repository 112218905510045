import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';

import { SimpleNotificationsModule } from 'angular2-notifications';
import { JwtModule } from '@auth0/angular-jwt';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Auth0Module } from '@sitespirit/ngx-auth0';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ScrollSpyModule } from 'ngx-scrollspy';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Components
import { BulkTagAddComponent } from '@components/bulk-tag-add/bulk-tag-add.component';
import { BulkDeleteDialogComponent } from '@components/bulk-delete-dialog/bulk-delete-dialog.component';
import { AdvanceQueryDialogComponent } from '@components/advance-query-dialog/advance-query-dialog.component';
import { BulkDownloadDialogComponent } from '@components/bulk-download-dialog/bulk-download-dialog.component';
import { AddCropDialogComponent } from '@components/add-crop-dialog/add-crop-dialog.component';
import { TagAddComponent } from '@components/tag-add/tag-add.component';

// Pages
import { ImageViewPageComponent } from '@pages/image-view-page/image-view-page.component';
import { NotFoundPageComponent } from '@pages/not-found-page/not-found-page.component';
import { ImageDetailDummyPageComponent } from '@pages/image-detail-dummy-page/image-detail-dummy-page.component';

// Services
import { AuthService, AuthGuard } from '@services/auth.service';
import { GlobalErrorHandler } from '@services/error-handler';
import { ImageService } from '@services/image.service';
import { TagService } from '@services/tag.service';
import { CropResolutionService } from '@services/cropresolution.service';
import { NotificationService } from '@services/notification.service';
import { AblyService } from '@services/ably.service';
import { WindowRef } from '@services/window.service';
import { CsvService } from '@services/csv.service';

// Modules
import { SharedModule } from '@modules/shared.module';

// Actions
import { AuthActions } from '@actions/auth.actions';
import { ImageActions } from '@actions/image.actions';
import { SelectedImageActions } from '@actions/selectedimage.actions';
import { TagActions } from '@actions/tag.actions';
import { CropResolutionActions } from '@actions/cropresolution.actions';
import { AblyActions } from '@actions/ably.actions';

// Reducers
import { AuthReducer } from '@reducers/auth';
import { ImageReducer } from '@reducers/image';
import { ImageSearchReducer } from '@reducers/imagesearch';
import { SelectedImageReducer } from '@reducers/selectedimage';
import { TagReducer } from '@reducers/tag';
import { ManagerTagsReducer } from '@reducers/manager-tags';
import { TagSearchReducer } from '@reducers/tagsearch';
import { CropResolutionReducer } from '@reducers/cropresolution';
import { CropResolutionFetchReducer } from '@reducers/cropresolutionfetch';
import { AblyReducer } from '@reducers/ably';

// Effects
import { AuthEffects } from '@effects/auth.effects';
import { ImageEffects } from '@effects/image.effects';
import { TagEffects } from '@effects/tag.effects';
import { CropResolutionEffects } from '@effects/cropresolution.effects';

// Guards
import { RightsGuard } from '@guards/rights-guard';

import * as Sentry from '@sentry/angular-ivy';

registerLocaleData(localeEn);
export function tokenGetter() {
  return localStorage.getItem('id_token');
}

@NgModule({
  declarations: [
    AppComponent,
    ImageDetailDummyPageComponent,
    ImageViewPageComponent,
    NotFoundPageComponent,
    TagAddComponent,
    BulkTagAddComponent,
    BulkDownloadDialogComponent,
    BulkDeleteDialogComponent,
    AddCropDialogComponent,
    AdvanceQueryDialogComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'api.eu.apiconnect.ibmcloud.com',
          'service.us.apiconnect.ibmcloud.com',
          'service.eu-de.apiconnect.ibmcloud.com',
          'b48cgnc5ra.execute-api.us-east-1.amazonaws.com',
          'eddosgymxl.execute-api.eu-central-1.amazonaws.com'
        ]
      }
    }),
    Auth0Module.forRoot({
      WebAuthConfig: {
        domain: 'sitespirit.eu.auth0.com',
        clientID: 'tqNF2in8RSQkYIFuvjTxGXLZTA1dMEzT',
        scope: 'openid profile email',
        responseType: 'token',
        redirectUri: location.href
      },
      connection: 'Username-Password-Authentication',
      proxyUrl: 'https://sd9cksc8i8.execute-api.eu-central-1.amazonaws.com/prod/auth0-proxy'
    }),
    StoreModule.forRoot({
      auth: AuthReducer,
      image: ImageReducer,
      imagesearch: ImageSearchReducer,
      tag: TagReducer,
      tagsearch: TagSearchReducer,
      cropresolution: CropResolutionReducer,
      cropresolutionfetch: CropResolutionFetchReducer,
      selectedimage: SelectedImageReducer,
      managerTags: ManagerTagsReducer,
      ablyState: AblyReducer
    }),
    EffectsModule.forRoot([AuthEffects, ImageEffects, TagEffects, CropResolutionEffects]),
    ClipboardModule,
    NgxMasonryModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    ScrollSpyModule,
    InfiniteScrollModule,
    SharedModule
  ],
  providers: [
    AuthService,
    AuthActions,
    SelectedImageActions,
    AblyActions,
    ImageService,
    ImageActions,
    AuthGuard,
    RightsGuard,
    TagService,
    TagActions,
    CropResolutionActions,
    CropResolutionService,
    NotificationService,
    AblyService,
    WindowRef,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    GlobalErrorHandler,
    { provide: LOCALE_ID, useValue: 'en-gb' },
    CsvService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
