import { Store } from '@ngrx/store';

import { CropResolutionActions } from '@actions/cropresolution.actions';
import { CropResolutionResultModel } from '@models/cropresolutionresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface CropResolutionFetchState {
  ids: string[];
  loading: boolean;
  totalRows?: number;
  offset?: number;
}

const initialState: CropResolutionFetchState = {
  ids: [],
  loading: false
};

export function CropResolutionFetchReducer(state: CropResolutionFetchState = initialState, action: ActionWithPayload): CropResolutionFetchState {
  switch (action.type) {
    case CropResolutionActions.FETCH_CROPRESOLUTIONS:
      return Object.assign({}, state, {
        loading: true
      });
    case CropResolutionActions.FETCH_CROPRESOLUTIONS_SUCCESS:
      const searchResult: CropResolutionResultModel = action.payload;

      let resultInfo = searchResult.rows.reduce((resultInfo: any, row) => {
        resultInfo.ids.push(row.id);
        return resultInfo;
      }, { ids: [] });

      return Object.assign({}, state, {
        totalRows: searchResult.total_rows,
        offset: searchResult.offset,
        loading: false,
        ids: resultInfo.ids
      });
    case CropResolutionActions.FETCH_CROPRESOLUTIONS_FAILED:
      return initialState;
    default:
      return state;
  }
};

export function getStatus() {
  return (state$: Store<CropResolutionFetchState>) => state$
    .select(s => s.loading);
}

export function getCropResolutionIds() {
  return (state$: Store<CropResolutionFetchState>) => state$
    .select(s => s.ids);
}
