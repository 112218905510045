import { Injectable } from '@angular/core';

import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class AuthActions {
  static LOGIN = 'LOGIN';
  static LOGIN_SUCCESS = 'LOGIN_SUCCESS';
  static LOGIN_FAILED = 'LOGIN_FAILED';
  static IS_LOGGED_IN = 'IS_LOGGED_IN';

  static LOGOUT = 'LOGOUT';
  static LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
  static LOGOUT_FAILED = 'LOGOUT_FAILED';
  static IS_LOGGED_OUT = 'IS_LOGGED_OUT';

  public login(): ActionWithPayload {
    return {
      type: AuthActions.LOGIN
    };
  };

  loginSuccess(profile: Object): ActionWithPayload {
    return {
      type: AuthActions.LOGIN_SUCCESS,
      payload: profile
    };
  };

  loginFailed(error: Object): ActionWithPayload {
    return {
      type: AuthActions.LOGIN_FAILED,
      payload: error
    };
  };

  IsloggedIn(profile: Object): ActionWithPayload {
    return {
      type: AuthActions.IS_LOGGED_IN,
      payload: profile
    };
  };

  logout(): ActionWithPayload {
    return {
      type: AuthActions.LOGOUT
    };
  };

  logoutSuccess(): ActionWithPayload {
    return {
      type: AuthActions.LOGOUT_SUCCESS
    };
  };

  logoutFailed(error: Object): ActionWithPayload {
    return {
      type: AuthActions.LOGOUT_FAILED,
      payload: error
    };
  };

  IsloggedOut(): ActionWithPayload {
    return {
      type: AuthActions.IS_LOGGED_OUT
    };
  };
}
