import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { tokenGetter } from '../app.module';

@Injectable()
export class TagService {
  cloudantProxyUrl = 'https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/';
  openwhiskUrl = 'https://sd9cksc8i8.execute-api.eu-central-1.amazonaws.com/prod/';
  header = {
    headers: new HttpHeaders().set('Authorization', `${tokenGetter()}`)
  };

  constructor(private authHttp: HttpClient) { }

  search(search: string, bookmark?: any): Observable<any> {
    if (search == null || search === '') {
      search = '*:*';
    }
    let searchQuery: Object;
    if (bookmark != null) {
      searchQuery = { query: search, include_docs: true, sort: 'id<string>', limit: 200, bookmark: bookmark };
    } else {
      searchQuery = { query: search, include_docs: true, sort: 'id<string>', limit: 200 };
    }
    return this.authHttp.post(this.cloudantProxyUrl + 'db/tagsearch', searchQuery);
  }

  add(obj: Object): Observable<any> {
    let fullObj: Object = obj;
    return this.authHttp.post(this.openwhiskUrl + 'tag', fullObj, this.header);
  }

  update(obj: any): Observable<any> {
    let postObj: any = {
      action: 'update',
      tag: obj.oldId || obj._id,
      tagChanges: {
        className: obj.className
      }
    };
    if (obj.oldId != null) {
      postObj.tagChanges.id = obj._id;
    }
    return this.authHttp.post(this.openwhiskUrl + 'bulk/tag', postObj, this.header);
  }

  delete(obj: any): Observable<any> {
    return this.authHttp.post(this.openwhiskUrl + 'bulk/tag', {
      tag: obj._id,
      action: 'delete'
    }, this.header);
  }

  merge(obj: any): Observable<any> {
    return this.authHttp.post(this.openwhiskUrl + 'bulk/tag', obj, this.header);
  }

  batchDelete(tags: any): Observable<any> {
    return this.authHttp.post(this.openwhiskUrl + 'tags/', tags, this.header);
  }

  retrieveTag(id: string): Observable<any> {
    return this.authHttp.get(this.cloudantProxyUrl + 'db/' + id);
  }

  getAll(): Observable<any> {
    return this.authHttp.get(this.cloudantProxyUrl + 'db/alltags?limit=100').pipe(map((x: any) => x.rows));
  }

}
