import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

// Set tracesSampleRate to 1.0 to capture 100% of transactions during testing for performance monitoring.
// We recommend adjusting this value in production
let sampleRate = 0.2;
if (window.location.href.indexOf('localhost') > -1) {
  sampleRate = 1.0;
}

Sentry.init({
  dsn: 'https://e96766cc5fe1209658e9be96bbf9c1d0@o4504603852144640.ingest.sentry.io/4505708026331136',
  sendClientReports: false,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    }),
  ],
  tracesSampleRate: sampleRate,
  tracePropagationTargets: ["localhost", /^https\:\/\/admin\.mediaspirit\.io/],

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
