import { CropResolutionActions } from '@actions/cropresolution.actions';
import { CropResolutionModel } from '@models/cropresolution';
import { CropResolutionResultModel } from '@models/cropresolutionresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface CropResolutionState {
  ids: string[];
  entities: { [id: string]: CropResolutionModel };
}

const initialState: CropResolutionState = {
  ids: [],
  entities: {}
};

export function CropResolutionReducer(state: CropResolutionState = initialState, action: ActionWithPayload): CropResolutionState {
  switch (action.type) {
    case CropResolutionActions.FETCH_CROPRESOLUTIONS_SUCCESS:
      const searchResult: CropResolutionResultModel = action.payload;

      let resultInfo = searchResult.rows.reduce((resultInfo: any, row) => {
        resultInfo.ids.push(row.id);
        resultInfo.entities[row.id] = row.doc;
        return resultInfo;
      }, { ids: [], entities: {} });

      return {
        ids: resultInfo.ids,
        entities: resultInfo.entities
      };
    default:
      return state;
  }
};
