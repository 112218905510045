import { AblyActions } from '@actions/ably.actions';
import { ActionWithPayload } from '@models/action-with-payload';

export const busy = 'false';

export function AblyReducer(state: any = busy, action: ActionWithPayload): any {
    switch (action.type) {
        case AblyActions.BUSY:
            state = action.payload;
            return state;
        case AblyActions.REFRESH:
            state = action.payload;
            return state;
        default:
            return state;
    }
};
