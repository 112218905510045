import { ActionWithPayload } from '@models/action-with-payload';

export function ManagerTagsReducer(state: any[] = [], action: ActionWithPayload): any[] {
    switch (action.type) {
        case 'MANAGER_TAGS_LOAD':
            state = action.payload;
            return state;
        case 'MANAGER_TAGS_MERGE':
            const flatArr = state.map(x => x._id);
            const sourceTagIndex = flatArr.indexOf(action.payload.sourceTag);
            const destinationTagIndex = flatArr.indexOf(action.payload.destinationTag);
            if (sourceTagIndex > -1 && destinationTagIndex > -1) {
                state[destinationTagIndex].count += state[sourceTagIndex].count;
                state[sourceTagIndex].count = 0;
                state[sourceTagIndex].deleted = true;
            }
            return state;
        default:
            return state;
    }
};
