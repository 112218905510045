import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@services/auth.service';
import { AppState } from '@services/appstate.service';
import { AuthActions } from '@actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private auth: AuthService,
    private actions$: Actions,
    private store: Store<AppState>,
    private authActions: AuthActions,
    private router: Router
  ) { }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN),
      map(() => this.auth.login()
        .then((profile: any) => this.store.dispatch(this.authActions.loginSuccess(profile)))
        .catch((err) => of(this.store.dispatch(this.authActions.loginFailed(err))))
      )
    ),
    { dispatch: false }
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LOGIN_SUCCESS),
      map(() => this.router.navigate(['']))
    ),
    { dispatch: false }
  )
};
