
<h1 mat-dialog-title>Add new tag</h1>
<div mat-dialog-content>
    <ul class="search-container">
        <li class="search">
            <div class="search-wrapper card hoverable">
                <select class="ms-search-input" placeholder="Color" [(ngModel)]="tag.className">
                    <option *ngFor="let option of colors" value="{{option.color}}">{{option.label}}</option>
                </select>
                <div class="search-results"></div>
            </div>
        </li>
    </ul>
    <ul class="search-container">
        <li class="search">
            <div class="search-wrapper card hoverable">
                <input class="ms-search-input" placeholder="New tag name" [(ngModel)]="tag._id" (keyup.enter)="addTag(tag)"><i class="material-icons" (click)="addTag(tag)">add_circle</i>
                <div class="search-results"></div>
            </div>
        </li>
    </ul>
    <p *ngIf="status != null && (status.error || status.status == 409 || status.status == 500 || status.status == 501)">{{status.message}}</p>
    <p *ngIf="status.validateError" class="validateError">{{status.validateError}}</p>
    <img *ngIf="loading" src="/assets/img/logo-preloader.png" alt="..." class="logo-loading animate-flicker" />
    <p *ngIf="status.ok || status.status == 200">{{status.id}} tag added!</p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close('yes')">Close (ESC)</button>
    <button mat-button class="tag-create-button" (click)="addTag(tag, true)">Create and close</button>
</div>
