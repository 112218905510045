import { Injectable } from '@angular/core';

import { ImageModel } from '@models/image';
import { TagModel } from '@models/tag';
import { ImageFulltextSearchResultModel } from '@models/imagefulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class ImageActions {
    static ADD_IMAGE = 'ADD_IMAGE';
    static UPDATE_IMAGE = 'UPDATE_IMAGE';
    static UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
    static UPDATE_IMAGE_PROPERTIES = 'UPDATE_IMAGE_PROPERTIES';
    static UPDATE_IMAGE_PROPERTIES_SUCCESS = 'UPDATE_IMAGE_PROPERTIES_SUCCESS';
    static UPDATE_IMAGE_EDIT = 'UPDATE_IMAGE_EDIT';
    static UPDATE_IMAGE_EDIT_SUCCESS = 'UPDATE_IMAGE_EDIT_SUCCESS';
    static ADD_IMAGE_TAG = 'ADD_IMAGE_TAG';
    static ADD_RECOGNIZED_IMAGE_TAG = 'ADD_RECOGNIZED_IMAGE_TAG';
    static DELETE_COGNITIVE_TAG = 'DELETE_COGNITIVE_TAG';
    static DELETE_IMAGE_TAG = 'DELETE_IMAGE_TAG';
    static DELETE_IMAGE = 'DELETE_IMAGE';
    static DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
    static COGNITIVE_RECOGNIZE_IMAGE = 'COGNITIVE_RECOGNIZE_IMAGE';
    static COGNITIVE_RECOGNIZE_IMAGE_SUCCESS = 'COGNITIVE_RECOGNIZE_IMAGE_SUCCESS';
    static LOAD_IMAGE = 'LOAD_IMAGE';
    static SEARCH_IMAGE = 'SEARCH_IMAGE';
    static SEARCH_IMAGE_SUCCESS = 'SEARCH_IMAGE_SUCCESS';
    static SEARCH_IMAGE_FAILED = 'SEARCH_IMAGE_FAILED';
    static SEARCH_IMAGE_NEXTPAGE = 'SEARCH_IMAGE_NEXTPAGE';
    static SEARCH_IMAGE_NEXTPAGE_SUCCESS = 'SEARCH_IMAGE_NEXTPAGE_SUCCESS';
    static SEARCH_IMAGE_NEXTPAGE_FAILED = 'SEARCH_IMAGE_NEXTPAGE_FAILED';
    static CHANGE_IMAGE_PRIORITY = 'CHANGE_IMAGE_PRIORITY';
    static CHANGE_IMAGE_PRIORITY_SUCCESS = 'CHANGE_IMAGE_PRIORITY_SUCCESS';
    static CHANGE_IMAGE_PRIORITY_FAILED = 'CHANGE_IMAGE_PRIORITY_FAILED';

    addImage(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.ADD_IMAGE,
            payload: image
        };
    }

    addImageTag(image: ImageModel, tag: TagModel): ActionWithPayload {
        return {
            type: ImageActions.ADD_IMAGE_TAG,
            payload: {
                image: image,
                tag: tag
            }
        };
    }

    changeImagePriority(image: ImageModel, tag: TagModel, priority: any): ActionWithPayload {
        if (priority == null) {
            priority = null;
        }
        return {
            type: ImageActions.CHANGE_IMAGE_PRIORITY,
            payload: {
                image: image,
                tag: tag,
                priority: priority
            }
        };
    }

    changeImagePrioritySuccess(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.CHANGE_IMAGE_PRIORITY_SUCCESS,
            payload: image
        };
    }

    addRecognizedImageTag(image: ImageModel, tag: string): ActionWithPayload {
        return {
            type: ImageActions.ADD_RECOGNIZED_IMAGE_TAG,
            payload: {
                image: image,
                tag: tag
            }
        };
    }

    deleteRecognizedCognitiveTag(image: ImageModel, tag: TagModel): ActionWithPayload {
        return {
            type: ImageActions.DELETE_COGNITIVE_TAG,
            payload: {
                image: image,
                tag: tag
            }
        };
    }

    deleteImageTag(image: ImageModel, tag: TagModel, tagIndex: any): ActionWithPayload {
        return {
            type: ImageActions.DELETE_IMAGE_TAG,
            payload: {
                image: image,
                tag: tag,
                tagIndex: tagIndex
            }
        };
    }

    updateImage(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE,
            payload: image
        };
    }

    updateImageSuccess(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE_SUCCESS,
            payload: image
        };
    }

    updateImageProperties(object: any): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE_PROPERTIES,
            payload: object
        };
    }

    updateImagePropertiesSuccess(object: any): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE_PROPERTIES_SUCCESS,
            payload: object
        };
    }

    updateImageEdit(object: any): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE_EDIT,
            payload: object
        };
    }

    updateImageEditSuccess(object: any): ActionWithPayload {
        return {
            type: ImageActions.UPDATE_IMAGE_EDIT_SUCCESS,
            payload: object
        };
    }

    loadImage(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.LOAD_IMAGE,
            payload: image
        };
    }

    cognitiveRecognizeImage(imageId: string): ActionWithPayload {
        return {
            type: ImageActions.COGNITIVE_RECOGNIZE_IMAGE,
            payload: imageId
        };
    }

    cognitiveRecognizeImageSuccess(imageId: string): ActionWithPayload {
        return {
            type: ImageActions.COGNITIVE_RECOGNIZE_IMAGE_SUCCESS,
            payload: imageId
        };
    }

    deleteImage(image: ImageModel): ActionWithPayload {
        return {
            type: ImageActions.DELETE_IMAGE,
            payload: image
        };
    }

    deleteImageSuccess(response: any): ActionWithPayload {
        return {
            type: ImageActions.DELETE_IMAGE_SUCCESS,
            payload: response
        };
    }

    searchImage(queryObj: object): ActionWithPayload {
        return {
            type: ImageActions.SEARCH_IMAGE,
            payload: queryObj
        };
    }

    searchImageSuccess(searchResult: ImageFulltextSearchResultModel, sort: any = null): ActionWithPayload {
        searchResult.sort = sort;
        return {
            type: ImageActions.SEARCH_IMAGE_SUCCESS,
            payload: searchResult
        };
    };

    searchImageFailed(error: Object): ActionWithPayload {
        return {
            type: ImageActions.SEARCH_IMAGE_FAILED,
            payload: error
        };
    };

    searchImageNextPage(): ActionWithPayload {
        return {
            type: ImageActions.SEARCH_IMAGE_NEXTPAGE
        };
    }

    searchImageNextPageSuccess(searchResult: ImageFulltextSearchResultModel): ActionWithPayload {
        return {
            type: ImageActions.SEARCH_IMAGE_NEXTPAGE_SUCCESS,
            payload: searchResult
        };
    };

    searchImageNextPageFailed(error: Object): ActionWithPayload {
        return {
            type: ImageActions.SEARCH_IMAGE_NEXTPAGE_FAILED,
            payload: error
        };
    };
}
