import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-add-crop-dialog',
  templateUrl: './add-crop-dialog.component.html',
  styleUrls: ['./add-crop-dialog.component.scss']
})
@Sentry.TraceClassDecorator()
export class AddCropDialogComponent implements OnInit {
  description: string;
  path: string;
  width = 0;
  height = 0;
  ratio = 1;
  previewWidth = 300;
  previewHeight = 300;
  quality = 70;
  data: any;
  selectedExtention = 'keep';

  constructor(private matDialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.data = this.matDialogRef.componentInstance;
  }

  recalc(key: any) {
    this.width = Number(Number(this.width).toFixed(0));
    this.height = Number(Number(this.height).toFixed(0));
    this.ratio = Number(this.ratio);
    if ((key === 'ratio' || key === 'width') && this.width > 0) {
      if (this.ratio > 0) {
        this.height = Number(Number(this.width / this.ratio).toFixed(0));
      } else {
        this.height = Number(Number(this.width).toFixed(0));
      }
    }
    if (this.width > 0 && this.height > 0) {
      if (this.width <= 300 && this.height <= 300) {
        this.previewWidth = this.width;
        this.previewHeight = this.height;
      } else {
        if (this.width > this.height) {
          this.previewWidth = 300;
          this.previewHeight = (this.height / this.width) * 300;
        } else {
          this.previewWidth = (this.width / this.height) * 300;
          this.previewHeight = 300;
        }
      }
    } else {
      this.previewWidth = 300;
      this.previewHeight = 300;
    }
    if (key === 'height') {
      if (this.width > 0) {
        this.ratio = Number(this.width / this.height);
      } else {
        this.ratio = 0;
      }
    }
  }

  increase(key: any) {
    if (key === 'height') {
      this.height++;
    } else if (key === 'width') {
      this.width++;
    } else if (key === 'ratio') {
      this.ratio = Number(Number(this.ratio + 0.1).toFixed(2));
    } else if (key === 'quality') {
      if (this.quality !== 100) {
        this.quality++;
      }
    }
    this.recalc(key);
  }

  decrease(key: any) {
    if (key === 'height') {
      if (this.height - 1 >= 0) {
        this.height--;
      }
    } else if (key === 'width') {
      if (this.width - 1 >= 0) {
        this.width--;
      }
    } else if (key === 'ratio') {
      if (this.ratio - 0.1 >= 0) {
        this.ratio = Number(Number(this.ratio - 0.1).toFixed(2));
      }
    } else if (key === 'quality') {
      if (this.quality - 1 >= 0) {
        this.quality--;
      }
    }
    this.recalc(key);
  }

  checkDisabled() {
    if (this.description && this.description !== '' && this.path && this.path !== '' && this.height > 0 && this.width > 0) {
      if (this.path.match(/.+?\/.+?/g) && this.path.match(/.+?\/.+?/g).length === 1) {
        if (this.path.match(/\//g) && this.path.match(/\//g).length === 1) {
          const checkValue = Number(this.width) + Number(this.ratio);
          for (const crop of this.data) {
            if (checkValue === Number(Number(crop.width) + Number(crop.ratio))) {
              if (this.description === crop.name || this.path === crop.path) {
                return true;
              }
            }
          }
          return false;
        }
      }
    }
    return true;
  }

  getData() {
    return {
      result: true,
      description: this.description,
      width: this.width,
      height: this.height,
      ratio: this.ratio,
      path: this.path,
      outputExtension: this.selectedExtention
    }
  }
}
