import { Injectable } from '@angular/core';

import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class AblyActions {
    static BUSY = 'BUSY';
    static REFRESH = 'REFRESH';

    busy(): ActionWithPayload {
        return {
            type: AblyActions.BUSY,
            payload: {
                value: 'true'
            }
        };
    }

    refresh(): ActionWithPayload {
        return {
            type: AblyActions.REFRESH,
            payload: {
                value: 'refresh'
            }
        };
    }
}
