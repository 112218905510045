import { Store } from '@ngrx/store';

import { TagActions } from '@actions/tag.actions';
import { TagFulltextSearchResultModel } from '@models/tagfulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface TagSearchState {
    ids: string[];
    orders: Array<Array<number>>;
    loading: boolean;
    query: string;
    totalRows?: number;
    bookmark?: string;
}

const initialState: TagSearchState = {
    ids: [],
    orders: [],
    loading: false,
    query: ''
};

export function TagSearchReducer(state: TagSearchState = initialState, action: ActionWithPayload): TagSearchState {
    switch (action.type) {
        case TagActions.SEARCH_TAG:
            return Object.assign({}, state, {
                query: action.payload,
                loading: true
            });
        case TagActions.SEARCH_TAG_SUCCESS:
            const searchResult: TagFulltextSearchResultModel = action.payload;

            let resultInfo = searchResult.rows.reduce((resultInfo: any, row: any) => {
                resultInfo.ids.push(row.id);
                resultInfo.orders.push(row.order);
                return resultInfo;
            }, { ids: [], orders: [] });

            return Object.assign({}, state, {
                totalRows: searchResult.total_rows,
                bookmark: searchResult.bookmark,
                loading: false,
                ids: resultInfo.ids,
                orders: resultInfo.orders
            });
        case TagActions.SEARCH_TAG_FAILED:
            return initialState;
        default:
            return state;
    }
};

export function getStatus() {
    return (state$: Store<TagSearchState>) => state$
        .select(s => s.loading);
}

export function getTagIds() {
    return (state$: Store<TagSearchState>) => state$
        .select(s => s.ids);
}

export function getQuery() {
    return (state$: Store<TagSearchState>) => state$
        .select(s => s.query);
}
