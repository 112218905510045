import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ImageActions } from '@actions/image.actions';
import { ImageModel } from '@models/image';
import { ImageFulltextSearchResultModel } from '@models/imagefulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';

export interface ImageState {
  ids: string[];
  entities: { [id: string]: ImageModel };
}

const initialState: ImageState = {
  ids: [],
  entities: {}
};

export function ImageReducer(state: ImageState = initialState, action: ActionWithPayload): ImageState {
  switch (action.type) {
    case ImageActions.SEARCH_IMAGE_SUCCESS:
      const searchResultOne: ImageFulltextSearchResultModel = action.payload;

      let resultInfoOne = searchResultOne.rows.reduce((resultInfoOne: any, row: any) => {
        const document: any = { ...row.doc };
        if (resultInfoOne.ids.indexOf(row.id) === -1) {
          resultInfoOne.ids.push(row.id);
        }
        if (document.hasOwnProperty('priority') === false) {
          document.priority = {};
        }
        if (document.nlCleanUrl != null) {
          document.nlCleanUrl = '/' + encodeURIComponent(document.nlCleanUrl.substring(1)).replace(/%20/g, ' ');
        }
        resultInfoOne.entities[row.id] = document;
        return resultInfoOne;
      }, { ids: [], entities: {} });
      return {
        ids: resultInfoOne.ids,
        entities: resultInfoOne.entities
      };
    case ImageActions.SEARCH_IMAGE_NEXTPAGE_SUCCESS:
      const searchResultTwo: ImageFulltextSearchResultModel = action.payload;

      let resultInfoTwo = searchResultTwo.rows.reduce((resultInfoTwoData: any, row: any) => {
        const document: any = { ...row.doc };
        if (state.ids.indexOf(row.id) === -1) {
          resultInfoTwoData.ids.push(row.id);
        }
        if (document.hasOwnProperty('priority') === false) {
          document.priority = {};
        }
        if (document.nlCleanUrl != null) {
          document.nlCleanUrl = '/' + encodeURIComponent(document.nlCleanUrl.substring(1)).replace(/%20/g, ' ');
        }
        resultInfoTwoData.entities[row.id] = document;
        return resultInfoTwoData;
      }, { ids: [], entities: {} });
      let newStateTwo = {
        ids: state.ids.concat(resultInfoTwo.ids),
        entities: Object.assign({}, state.entities, resultInfoTwo.entities)
      };
      return newStateTwo;
    case ImageActions.LOAD_IMAGE:
      let image: ImageModel = action.payload;
      if (image.nlCleanUrl != null) {
        image.nlCleanUrl = '/' + encodeURIComponent(image.nlCleanUrl.substring(1)).replace(/%20/g, ' ');
      }
      if (!image.focusPoint) {
        image.focusPoint = { percentageX: 50, percentageY: 50, crop: {} };
      }
      if (!image.colorSpace) {
        image.colorSpace = 'sRGB';
      }
      if (state.ids.indexOf(image._id) > 0) {
        return state;
      }
      return {
        ids: [...state.ids, image._id],
        entities: Object.assign({}, state.entities, {
          [image._id]: image
        })
      };
    case ImageActions.UPDATE_IMAGE_SUCCESS:
      const payload: any = { ...action.payload };
      if (payload.hasOwnProperty('priority') === false) {
        payload.priority = {};
      }
      return {
        ids: [...state.ids],
        entities: Object.assign({}, state.entities, {
          [payload._id]: payload
        })
      }
    case ImageActions.CHANGE_IMAGE_PRIORITY_SUCCESS:
      return {
        ids: [...state.ids],
        entities: Object.assign({}, state.entities, {
          [action.payload._id]: action.payload
        })
      }
    default:
      return state;
  }
};

export function getImageEntities(): any {
  return (state$: Store<ImageState>) => state$
    .select(s => s.entities);
};

export function getImage(id: string) {
  return (state$: Store<ImageState>) => state$
    .select(s => s.entities[id]);
}

export function getImages(ImageIds: string[]) {
  return (state$: Store<ImageState>) => state$
    .pipe(getImageEntities(), map((entities: any) => ImageIds.map(id => entities[id])));
}

export function hasImage(id: string) {
  return (state$: Store<ImageState>) => state$
    .select(s => (s.ids.indexOf(id) > 0));
}
