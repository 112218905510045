import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { ImageFulltextSearchResultModel } from '@models/imagefulltextsearchresult';
import { ActionWithPayload } from '@models/action-with-payload';
import { ImageActions } from '@actions/image.actions';

export interface ImageSearchState {
  ids: string[];
  orders: Array<Array<number>>;
  loading: boolean;
  query: string;
  totalRows?: number;
  bookmark?: string;
  sort?: string;
  error?: Object;
}

const initialState: ImageSearchState = {
  ids: [],
  orders: [],
  loading: false,
  query: '',
  error: {}
};

export function ImageSearchReducer(state: ImageSearchState = initialState, action: ActionWithPayload): ImageSearchState {
  switch (action.type) {
    case ImageActions.SEARCH_IMAGE:
      return Object.assign({}, state, {
        query: action.payload,
        loading: true,
        error: {}
      });
    case ImageActions.SEARCH_IMAGE_SUCCESS:
      const searchResultOne: ImageFulltextSearchResultModel = action.payload;

      let resultInfoOne = searchResultOne.rows.reduce((resultInfoOneData: any, row) => {
        resultInfoOneData.ids.push(row.id);
        resultInfoOneData.orders.push(row.order);
        return resultInfoOneData;
      }, { ids: [], orders: [] });

      return Object.assign({}, state, {
        totalRows: searchResultOne.total_rows,
        bookmark: searchResultOne.bookmark,
        sort: searchResultOne.sort,
        loading: false,
        ids: resultInfoOne.ids,
        orders: resultInfoOne.orders,
        error: {}
      });
    case ImageActions.SEARCH_IMAGE_FAILED:
      return Object.assign({}, initialState, {
        error: action.payload
      });
    case ImageActions.SEARCH_IMAGE_NEXTPAGE:
      let newState = { ...state };
      newState.loading = true;
      return newState;
    case ImageActions.SEARCH_IMAGE_NEXTPAGE_SUCCESS:
      const searchResultTwo: ImageFulltextSearchResultModel = action.payload;

      let resultInfoTwo = searchResultTwo.rows.reduce((resultInfoTwoData: any, row: any) => {
        if (state.ids.indexOf(row.id) === -1) {
          resultInfoTwoData.ids.push(row.id);
        }
        resultInfoTwoData.orders.push(row.order);
        return resultInfoTwoData;
      }, { ids: [], orders: [] });

      let testNewState = {
        ids: state.ids.concat(resultInfoTwo.ids),
        orders: state.orders.concat(resultInfoTwo.orders),
        bookmark: searchResultTwo.bookmark,
        loading: false
      };

      return Object.assign({}, state, testNewState);
    default:
      return state;
  }
};

export function getStatus() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.loading);
}

export function getImageIds() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.ids);
}

export function getQuery() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.query);
}

export function getBookmark() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.bookmark);
}

export function getSort() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.sort);
}

export function getTotalRows() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.totalRows);
}

export function getCurrentLoadedRowNum() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.ids)
    .pipe(map(ids => ids.length));
}

export function getError() {
  return (state$: Store<ImageSearchState>) => state$
    .select(s => s.error);
}
