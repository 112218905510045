import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { AppState } from '@services/appstate.service';
import { ImageModel } from '@models/image';
import { TagModel } from '@models/tag';
import { getImageSearchQuery, getImageSearchBookmark, getImageSearchSort } from '../reducers';
import { tokenGetter } from '../app.module';

import * as _ from 'lodash';

@Injectable()
export class ImageService {
    localPouch: any;
    remotePouch: any;

    cloudantProxyUrl = 'https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/';
    openwhiskUrl = 'https://sd9cksc8i8.execute-api.eu-central-1.amazonaws.com/prod/';
    header = {
        headers: new HttpHeaders().set('Authorization', `${tokenGetter()}`)
    }

    constructor(
        private authHttp: HttpClient,
        private store: Store<AppState>
    ) { }

    startConnect(db: any) { }

    search(searchObj: any, limit = 20, bookmark: string = null): Observable<any> {
        let sortType = '-uploadedAt';
        if (searchObj.sortType === 'oldest') {
            sortType = '+uploadedAt';
        }
        let searchQuery = {};
        if (bookmark != null) {
            searchQuery = { query: searchObj.query, include_docs: true, limit: limit, sort: sortType, bookmark: bookmark };
        } else {
            searchQuery = { query: searchObj.query, include_docs: true, limit: limit, sort: sortType };
        }
        return this.authHttp.post(this.cloudantProxyUrl + 'db/imagesearch', searchQuery);
    }

    searchWithoutLimit(search: string, bookmark?: any): Observable<any> {
        if (bookmark != null) {
            const postQuery = { query: search, include_docs: true, limit: 200, sort: '-uploadedAt', bookmark: bookmark };
            return this.authHttp.post(this.cloudantProxyUrl + 'db/imagesearch', postQuery);
        }
        let searchQuery = { query: search, include_docs: true, limit: 200, sort: '-uploadedAt' };
        return this.authHttp.post(this.cloudantProxyUrl + 'db/imagesearch', searchQuery);
    }

    db() {
        return this.authHttp.get(this.cloudantProxyUrl + 'db');
    }

    retrieveImage(id: string): Observable<any> {
        return this.authHttp.get(this.cloudantProxyUrl + 'db/' + id);
    }

    recoverImage(id: any) {
        return this.authHttp.get(this.openwhiskUrl + 'image/undelete/' + id, this.header).toPromise().then((res) => {
            console.log(res);
            return Promise.resolve(id);
        }, (err) => {
            console.log(err);
            return Promise.resolve(id);
        });
    }

    getStatus(): Observable<any> {
        return this.authHttp.get(this.cloudantProxyUrl + 'db/status').pipe(map((x: any) => x.rows));
    }

    getUploadStatus(): Observable<any> {
        return this.authHttp.get(this.openwhiskUrl + 'status/uploadbucket', this.header).pipe(map((x: any) => x.files));
    }

    getStickers(): Observable<any> {
        return this.authHttp.get(this.cloudantProxyUrl + 'db/stickers').pipe(map((x: any) => x.rows));
    }

    getDuplicates(): Observable<any> {
        return this.authHttp.get(this.cloudantProxyUrl + 'db/duplicates').pipe(map((x: any) => x.rows.reduce((all: any, item: any, index: any) => {
            if (item.value != null) {
                let keyParts = item.key.split('|');
                all.push({ name: keyParts[0] + ' duplicate: ' + keyParts[1], size: Number(keyParts[1]), ids: item.value, type: 'duplicate' });
            }
            return all;
        }, [])));
    }

    getTotalCrops(): Observable<any> {
        return this.authHttp.get(this.cloudantProxyUrl + 'db/imagecropres').pipe(map((x: any) => x.rows));
    }

    getRejected(): Observable<any> {
        return this.authHttp.get(this.openwhiskUrl + 'status/uploadrejected', this.header).pipe(map((x: any) => x.files));
    }

    retryUpload(paths: string[]): Observable<any> {
        return this.authHttp.post(this.openwhiskUrl + 'status/uploadbucket', { action: 'retrigger', paths: paths }, this.header);
    }

    retryPendingUpload(id: string): Observable<any> {
        return this.authHttp.post(this.openwhiskUrl + `status/uploadpending/${id}`, {}, this.header);
    }

    deleteUpload(paths: string[]): Observable<any> {
        return this.authHttp.post(this.openwhiskUrl + 'status/uploadbucket', { action: 'delete', paths: paths }, this.header);
    }

    findDuplicates(imageId: any): Observable<any> {
        return this.authHttp.post(this.openwhiskUrl + 'image/duplicatecheck', { imageId: imageId }, this.header);
    }

    createCrops(imageId: any, crops: any): Observable<any> {
        const postObj = { imageId: imageId, crops: crops };
        return this.authHttp.post(this.openwhiskUrl + 'image/crop', postObj, this.header);
    }

    deleteRejected(paths: string[]): Observable<any> {
        return this.authHttp.post(this.openwhiskUrl + 'status/uploadrejected', { action: 'delete', paths: paths }, this.header);
    }

    deleteImage(image: ImageModel) {
        if (image.process == null) {
            image.process = {};
        }
        if (image.process.delete == null) {
            image.process.delete = {};
        }
        image.process.delete.pending = new Date();
        return this.authHttp.delete(this.openwhiskUrl + 'image/' + image._id, this.header).toPromise().then((res) => {
            return Promise.resolve(image);
        }, (err) => {
            return Promise.resolve(image);
        });
    }

    deleteImageById(imageId: string) {
        return this.authHttp.delete(this.openwhiskUrl + 'image/' + imageId, this.header).toPromise().then((res) => {
            return Promise.resolve(imageId);
        }, (err) => {
            return Promise.resolve(imageId);
        });
    }

    cognitiveRecognizeImage(imageId: string) {
        let postUrl = `${this.openwhiskUrl}image/cognitiverecognize`;
        return this.authHttp.post(postUrl, { 'imageIds': [imageId] }, this.header).toPromise();
    }

    updateImage(image: ImageModel) {
        return Promise.resolve(image); // this is now being used for optimistic UI, it triggers UPDATE_IMAGE_SUCCESS
    }

    updateImageProperties(object: any) {
        return this.authHttp.put(`${this.openwhiskUrl}image/${object._id}`, object.properties, this.header).pipe(map(response => {
            return response;
        })).toPromise().then((result: any) => {
            if (result != null && result.status === 200) {
                if (!object.image.process) {
                    object.image.process = {
                        moveCrops: {}
                    };
                } else if (!object.image.process.moveCrops) {
                    object.image.moveCrops = {};
                }
                object.image.process.moveCrops.done = true;
            }
            if (object.image != null && object.image.process != null && object.image.process.moveCrops != null
                && object.image.process.moveCrops.done != null) {
                object.image.process.moveCrops.done = true;
            }
            if (object.image != null) {
                return Promise.resolve(object.image);
            } else {
                return Promise.resolve(object);
            }
        });
    }

    base64toBlob(base64Data: any, contentType: any) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    updateImageEdit(object: any) {
        let formData: FormData = new FormData();
        let extension = object.encodedImage.substring('data:image/'.length, object.encodedImage.indexOf(';base64'));
        let fileName = `${object._id}.${extension.replace('jpeg', 'jpg')}`;

        return this.getUploadUrl().toPromise().then((conf) => {
            return this.getUploadFileConfig('_editchanged/' + fileName).toPromise().then((data) => {
                for (const property in data.params) {
                    if (data.params[property]) {
                        formData.append(property, data.params[property]);
                    }
                }
                let rawBase64 = object.encodedImage.replace(/^data:image\/\w+;base64,/, '');
                formData.append('file', this.base64toBlob(rawBase64, `image/${extension}`));
                formData.append('url', conf.endpoint_url);
                return this.authHttp.post(conf.endpoint_url, formData, { responseType: 'text' }).toPromise();
            });
        });
        // return this.authHttp.put(`${this.openwhiskUrl}image/${object._id}`,object.properties, this.header).map(response => response).toPromise();
    }

    addTag(image: ImageModel, tag: TagModel) {
        const imageClone = _.cloneDeep(image);
        if (imageClone.tags != null) {
            imageClone.tags.push(tag);
        }
        return this.authHttp.post(this.openwhiskUrl + 'image/tag/' + image._id, { tag: tag._id }, this.header).toPromise().then((res) => {
            return this.updateImage(imageClone);
        }).catch(e => {
            if (e.status === 409) {
                return image;
            }
            return e;
        });
    }

    changeImagePriority(image: ImageModel, tag: any, priority: number) {
        let priorityObject: any = {};
        if (image.hasOwnProperty('priority') === true) {
            priorityObject = image.priority;
        }
        if (priority !== 0 && priority != null) {
            priorityObject[tag._id] = priority;
        } else if (priority == null) {
            delete priorityObject[tag._id];
        }

        const keys = Object.keys(priorityObject);
        for (const key of keys) {
            if (key === '[object Object]') {
                delete priorityObject['[object Object]'];
                break;
            }
        }

        return this.authHttp.put(this.openwhiskUrl + 'image/' + image._id, { priority: priorityObject }, this.header).toPromise();
    }

    addRecognizedImageTag(image: ImageModel, tag: any) {
        tag._id = tag.class;
        let fullObj = {
            className: 'tag_cyan',
            _id: tag.class
        };
        this.authHttp.post(this.openwhiskUrl + 'tag/', fullObj, this.header).toPromise().then((res) => {
            return {
                image: image,
                tag: fullObj
            };
        }, (err) => {
            if (err.error === 'conflict' || err.status === 409) {
                return {
                    image: image,
                    tag: fullObj
                };
            }
            return err;
        });
        return Promise.resolve({ image: image, tag: fullObj });
    }

    deleteCognitiveTag(image: ImageModel, cognitiveTag: any) {
        if (image.recognition && image.recognition.length > 0) {
            for (const recog of image.recognition) {
                if (recog.images && recog.images.length > 0) {
                    for (const img of recog.images) {
                        if (img.classifiers && img.classifiers.length > 0) {
                            for (const classifier of img.classifiers) {
                                if (classifier.classes && classifier.classes.length > 0) {
                                    const index = classifier.classes.findIndex((tag: any) => {
                                        tag.class === cognitiveTag.class
                                    });
                                    classifier.classes.splice(index, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        return this.authHttp.delete(this.openwhiskUrl + 'image/cognitivetag/' + image._id + '?tag=' + cognitiveTag.class, this.header)
            .toPromise().then((res) => {
                return this.updateImage(image);
            });
    }

    deleteTag(image: ImageModel, tag: TagModel) {
        const imageClone = _.cloneDeep(image);
        imageClone.tags = image.tags.filter((item: any) => (item._id !== tag._id));
        return this.authHttp.delete(this.openwhiskUrl + 'image/tag/' + imageClone._id + '?tag='
            + encodeURIComponent(tag._id), this.header).toPromise().then((res) => {
                return this.updateImage(imageClone);
            });
    }

    searchNextPage(): Observable<any> {
        let currentQuery = '';
        this.store.pipe(getImageSearchQuery()).pipe(take(1)).subscribe(q => {
            const res: any = q;
            currentQuery = res.query;
        });

        if (currentQuery === '') {
            return observableThrowError('search query empty');
        }

        let bookMark = '';
        this.store.pipe(getImageSearchBookmark()).pipe(take(1)).subscribe(bm => {
            const res: any = bm;
            bookMark = res
        });

        let sortType = '-uploadedAt';
        this.store.pipe(getImageSearchSort()).pipe(take(1)).subscribe(x => {
            const res: any = x;
            if (res != null && res.sort != null && res.sort === 'oldest') {
                sortType = '+uploadedAt';
            }
        });

        let searchQuery: Object = { query: currentQuery, include_docs: true, sort: sortType, bookmark: bookMark, limit: 20 };
        return this.authHttp.post(this.cloudantProxyUrl + 'db/imagesearch', searchQuery);
    }

    getUploadUrl(): Observable<any> {
        return this.authHttp.get(this.openwhiskUrl + 'upload/url', this.header);
    }

    getUploadFileConfig(filename: string): Observable<any> {
        return this.authHttp.get(this.openwhiskUrl + 'upload/fileconfig?filename=' + encodeURIComponent(filename), this.header);
    }

    addMultipleImageTags(images: Array<string>, tags: Array<string>): Observable<any> {
        let postObject = {
            imageIds: images,
            tagIds: tags,
            action: 'add'
        };
        return this.authHttp.post(this.openwhiskUrl + 'bulk/imagetag', postObject, this.header);
    }

    deleteMultipleImageTags(images: Array<string>, tags: Array<string>): Observable<any> {
        let postObject = {
            imageIds: images,
            tagIds: tags,
            action: 'delete'
        };
        return this.authHttp.post(this.openwhiskUrl + 'bulk/imagetag', postObject, this.header);
    }

    deleteMultipleImages(images: Array<string>): Observable<any> {
        let postObject = {
            imageIds: images,
            action: 'delete'
        };
        return this.authHttp.post(this.openwhiskUrl + 'bulk/image', postObject, this.header);
    }

    purgeMultipleImages(images: Array<string>): Observable<any> {
        let postObject = {
            imageIds: images,
            action: 'purge'
        };
        return this.authHttp.post(this.openwhiskUrl + 'bulk/image', postObject, this.header);
    }
}
