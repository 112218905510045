import { Injectable } from '@angular/core';

import { CropResolutionModel } from '@models/cropresolution';
import { CropResolutionResultModel } from '@models/cropresolutionresult';
import { ActionWithPayload } from '@models/action-with-payload';

@Injectable()
export class CropResolutionActions {
    static ADD_CROPRESOLUTION = 'ADD_CROPRESOLUTION';
    static UPDATE_CROPRESOLUTION = 'UPDATE_CROPRESOLUTION';
    static DELETE_CROPRESOLUTION = 'DELETE_CROPRESOLUTION';
    static FETCH_CROPRESOLUTIONS = 'FETCH_CROPRESOLUTIONS';
    static FETCH_CROPRESOLUTIONS_SUCCESS = 'FETCH_CROPRESOLUTIONS_SUCCESS';
    static FETCH_CROPRESOLUTIONS_FAILED = 'FETCH_CROPRESOLUTIONS_FAILED';

    addCropResolution(cropres: CropResolutionModel): ActionWithPayload {
        return {
            type: CropResolutionActions.ADD_CROPRESOLUTION,
            payload: cropres
        };
    }

    updateCropResolution(cropres: CropResolutionModel): ActionWithPayload {
        return {
            type: CropResolutionActions.UPDATE_CROPRESOLUTION,
            payload: cropres
        };
    }

    deleteCropResolution(cropres: CropResolutionModel): ActionWithPayload {
        return {
            type: CropResolutionActions.DELETE_CROPRESOLUTION,
            payload: cropres
        };
    }

    fetchCropResolutions(): ActionWithPayload {
        return {
            type: CropResolutionActions.FETCH_CROPRESOLUTIONS
        };
    }

    fetchCropResolutionsSuccess(searchResult: CropResolutionResultModel): ActionWithPayload {
        return {
            type: CropResolutionActions.FETCH_CROPRESOLUTIONS_SUCCESS,
            payload: searchResult
        };
    };

    fetchCropResolutionsFailed(error: Object): ActionWithPayload {
        return {
            type: CropResolutionActions.FETCH_CROPRESOLUTIONS_FAILED,
            payload: error
        };
    };
}
