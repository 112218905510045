import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Event, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { TagAddComponent } from '@components/tag-add/tag-add.component';
import { AddCropDialogComponent } from '@components/add-crop-dialog/add-crop-dialog.component';
import { AuthService } from '@services/auth.service';
import { CropResolutionService } from '@services/cropresolution.service';
import { AppState } from '@services/appstate.service';
import { AblyService } from '@services/ably.service';
import { CropResolutionActions } from '@actions/cropresolution.actions';
import { getImageSearchTotalRows, getCropResolutionEntities } from './reducers';

import * as Sentry from '@sentry/angular-ivy';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {
    notificationOptions: any = {
        timeOut: 2000,
        lastOnBottom: true,
        showProgressBar: true,
        pauseOnHover: false,
        clickToClose: true,
        maxStack: 3
    };
    dialogRef: MatDialogRef<TagAddComponent>;
    addCropDialogRef: MatDialogRef<AddCropDialogComponent>;
    cropResolutions$: Observable<any>;
    cropResolutions: any;
    locationPath: string;
    showMobileMenu = false;
    searchTotalRows$: Observable<number>;
    loading = true;

    cropResTool: boolean;
    allowImageUpload = false;
    allowTagManager = false;
    allowNewTags = false;

    cropUrl: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private store: Store<AppState>,
        private auth: AuthService,
        public dialog: MatDialog,
        location: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private ablyService: AblyService,
        private http: HttpClient,
        private cropResolutionActions: CropResolutionActions,
        private cropResService: CropResolutionService
    ) {
        if (auth.authenticated() === true) {
            this.locationPath = location.path();
            store.dispatch(this.cropResolutionActions.fetchCropResolutions());
            this.cropResolutions$ = store.pipe(getCropResolutionEntities());
            this.cropResolutions$.subscribe(x => {
                this.cropResolutions = x;
                const cropResKeys = Object.keys(this.cropResolutions);
                if (cropResKeys.length > 0) {
                    this.cropResolutions = this.formatCropResolutions(cropResKeys);
                }
            });
        }
        this.searchTotalRows$ = this.store.pipe(getImageSearchTotalRows());
        this.cropResTool = false;
        this.authenticate();
        this.ablyService.start();
    }

    ngOnInit() {
        const profile = JSON.parse(localStorage.getItem('profile'));

        if (profile != null) {
            if (profile['https://mediaspirit.io/app_metadata'] != null) {
                if (profile['https://mediaspirit.io/app_metadata'].rights) {
                    if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagAdd') > -1) {
                        this.allowNewTags = true;
                    }
                    if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagAdd') > -1 ||
                        profile['https://mediaspirit.io/app_metadata'].rights.indexOf('tagDelete') > -1) {
                        this.allowTagManager = true;
                    }
                    if (profile['https://mediaspirit.io/app_metadata'].rights.indexOf('imageUpload') > -1) {
                        this.allowImageUpload = true;
                    }
                }
            }
        }

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd)
                , map(() => this.activatedRoute)
                , map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
                , filter(route => route.outlet === 'primary')
                , mergeMap(route => route.data))
            .subscribe((event) => {
                if (event['title'] != null) {
                    this.titleService.setTitle(event['title']);
                }
            });

        this.router.events.subscribe((routerEvent: Event) => {
            this.checkRouterEvent(routerEvent);
        });
        this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams['crop-res-tool'] != null && queryParams['crop-res-tool'] === 'true') {
                this.getIPAddress().subscribe((res: any) => {
                    if (res.ip === '62.251.83.180') {
                        if (profile != null && profile['userData'] != null && profile['https://mediaspirit.io/app_metadata'] != null) {
                            if (profile['https://mediaspirit.io/app_metadata'].rights &&
                                profile['https://mediaspirit.io/app_metadata'].rights.indexOf('cropResAdd') > -1) {
                                this.cropResTool = true;
                            }
                        }
                    }
                });
            }
        });
    }

    openAddTag() {
        this.dialogRef = this.dialog.open(TagAddComponent, {
            disableClose: false
        });

        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = null;
        });
    }

    authenticate(err: any = null) {
        if (!this.auth.authenticated()) {
            this.auth.login(err).then(x => { }).catch(e => {
                if (e !== 'Canceled login to change password') {
                    this.authenticate(e);
                }
            });
        } else {
            const profile = JSON.parse(localStorage.getItem('profile'));

            if (profile['https://mediaspirit.io/app_metadata'] != null) {
                if (profile['https://mediaspirit.io/app_metadata'].newApi === true) {
                    this.document.location.href = 'https://new-admin.mediaspirit.io';
                }
            }
        }
    }

    logout() {
        this.auth.logout().then(() => {
            location.reload();
        });
    }

    checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
            this.loading = true;
        }

        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.loading = false;
        }
    }

    AddCropRes() {
        this.addCropDialogRef = this.dialog.open(AddCropDialogComponent, {
            disableClose: false
        });
        this.addCropDialogRef.componentInstance = this.cropResolutions;

        this.addCropDialogRef.afterClosed().subscribe(res => {
            if (res.extention === 'keep') {
                delete res.extention;
            }
            if (res && res.result === true) {
                delete res.result;
                delete res.height;
                res.url = this.cropUrl;
                if (res.ratio === 0) {
                    res.ratio = 1;
                }
                this.cropResService.upload(res).subscribe((x) => {
                    console.log('Works :D')
                }, (err) => {
                    console.log(err)
                });
            }
            this.addCropDialogRef = null;
        });
    }

    getIPAddress() {
        return this.http.get('https://ipapi.co/json/');
    }

    formatCropResolutions(keys: any) {
        const res = [];
        for (const key of keys) {
            if (this.cropResolutions[key] && this.cropResolutions[key].url) {
                this.cropUrl = this.cropResolutions[key].url;
            }
            if (this.cropResolutions[key].ratio > 0 || this.cropResolutions[key].height != null) {
                res.push({
                    name: this.cropResolutions[key].description,
                    path: this.cropResolutions[key].path,
                    width: this.cropResolutions[key].width,
                    height: this.cropResolutions[key].height || Number(this.cropResolutions[key].width / this.cropResolutions[key].ratio).toFixed(0),
                    ratio: Number(this.cropResolutions[key].ratio).toFixed(2),
                });
            }
        }
        return res;
    }

    showMobileMenuItems() {
        this.showMobileMenu = !this.showMobileMenu;
    }
}
