import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TagActions } from '../actions/tag.actions';
import { TagService } from '../services/tag.service';
import { of } from 'rxjs';
import { filter, map, switchMap, catchError } from 'rxjs/operators';

import { ActionWithPayload } from '../models/action-with-payload';

@Injectable()
export class TagEffects {
    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.SEARCH_TAG),
            filter((action: ActionWithPayload) => action.payload !== '')
            , switchMap((action: ActionWithPayload) => this.tagService.search(action.payload, action.bookmark || null)
                .pipe(map(searchResult => this.tagActions.searchTagSuccess(searchResult))
                    , catchError((err) => of(this.tagActions.searchTagFailed(err)))))
        )
    );

    add$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.ADD_TAG),
            filter((action: ActionWithPayload) => action.payload !== '')
            , switchMap((action: ActionWithPayload) => this.tagService.add(action.payload)
                .pipe(map(response => this.tagActions.addTagSuccess(response))
                    , catchError((err) => of(this.tagActions.addTagFailed(err)))))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.UPDATE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagService.update(action.payload)
                .pipe(map(response => this.tagActions.updateTagSuccess(response))
                    , catchError((err) => of(this.tagActions.updateTagFailed(err)))))
        )
    );

    merge$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.MERGE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagService.merge(action.payload)
                .pipe(map(response => this.tagActions.mergeTagSuccess(response))
                    , catchError((err) => of(this.tagActions.mergeTagFailed(err)))))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.DELETE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagService.delete(action.payload)
                .pipe(map(response => this.tagActions.deleteTagSuccess(response))
                    , catchError((err) => of(this.tagActions.deleteTagFailed(err)))))
        )
    );

    batch_delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagActions.BATCH_DELETE_TAG),
            filter((action: ActionWithPayload) => action.payload !== null)
            , switchMap((action: ActionWithPayload) => this.tagService.batchDelete(action.payload)
                .pipe(map(response => this.tagActions.batchDeleteTagSuccess(response))
                    , catchError((err) => of(this.tagActions.batchDeleteTagFailed(err)))))
        )
    );

    constructor(
        private tagService: TagService,
        private actions$: Actions,
        private tagActions: TagActions,
    ) { }
};
