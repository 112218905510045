import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotificationService {
    hasPermission: boolean;
    showNotifications: boolean = true;
    constructor(private notificationService: NotificationsService) {
        this.hasPermission = false;

        let metaDataSelector = 'https://mediaspirit.io/app_metadata';
        let profile = JSON.parse(localStorage.getItem('profile'));
        if (profile != null && profile[metaDataSelector] != null && profile[metaDataSelector].notifications != null) {
            this.showNotifications = profile[metaDataSelector].notifications;
        }
    }

    success(title: any, message: any, object: any) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.success(title, message, object);
        }
    }

    alert(title: any, message: any) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.alert(title, message);
        }
    }

    error(title: any, message: any) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.error(title, message);
        }
    }

    info(title: any, message: any) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.info(title, message);
        }
    }

    bare(title: any, message: any) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.bare(title, message);
        }
    }

    html(title: any, message: any, object = {}) {
        if (this.hasPermission || this.showNotifications === false) {
            this.createPush(title, message);
        } else {
            this.notificationService.html(title, message, object);
        }
    }

    createPush(title: any, message: any) { }

}
